import React from 'react';
import '../../App.css';
import {Link} from 'react-router-dom';

const AccessibilityStatement = ({refToFooter}) => {
    
    window.scrollTo({top: 0});
    /*const {contextTheme} = useThemeContext()*/
    const scrollToFooter = () => {
        if (refToFooter.current) {
          refToFooter.current.scrollIntoView();
        }
      };


    return (
        <>
        {/*}
        <div className='container-child align-center margin-bottom-l margin-top-paragraph'>
        */}
        <div className='container-parent margin-bottom-xl'>
        <div className='container-child margin-top-paragraph'>
        <h1 className='accStatement'>DECLARACIÓN DE ACCESIBILIDAD</h1>
        <div className='div-text'>
            <p className="margin-top-m margin-bottom-m p-text">
                <span className='bold'>DIAS</span> se ha comprometido a hacer accesible su sitio web, de conformidad con las <span className='bold'>Pautas de Accesibilidad para el Contenido Web</span> (en adelante, <span className='bold'>WCAG</span>), para así garantizar un acceso universal a su sitio web, con el propósito de que pueda ser utilizado por el mayor número de personas, incluyendo personas con discapacidad. 
            </p>
            <p className="margin-bottom-l p-text">
                La presente declaración de accesibilidad se aplica al <span className='bold'>sitio web completo</span>.
            </p>
            <h2 className='heading-text-left margin-bottom-m border-left-padding'>
            Situación de Cumplimiento 
            </h2>

{/* PARCIALMENTE CONFORME

            <p className="margin-bottom-l">
            Este sitio web es <span className='bold'>parcialmente conforme</span> con las <span className='bold'>WCAG versión 2.2</span> en su <span className='bold'>nivel AA</span>, debido a la falta de conformidad de los aspectos que se indican a continuación.
            </p>
            <h2 className='heading-text-left margin-bottom-m'>
            Contenido no accesible
            </h2>
            <p className="margin-bottom-m">
            El contenido que se recoge a continuación no es accesible por lo siguiente:
            </p>
            <h3 className='p-large-left margin-bottom-m bold'>
            Falta de conformidad con las WCAG
            </h3>
            <ul className="margin-bottom-m">
                <li>No aplica</li>
            </ul>
            <h3 className='p-large-left margin-bottom-m bold'>
            Carga desproporcionada
            </h3>
            <ul className="margin-bottom-m">
                <li>No aplica</li>
            </ul>
            <h3 className='p-large-left margin-bottom-m bold'>
            El contenido no entra dentro del ámbito de las WCAG
            </h3>
            <ul className="margin-bottom-l">
                <li>No aplica</li>
            </ul>
*/}

{/* TOTALMENTE CONFORME... */}
            <p className="margin-bottom-l p-text">
            Este sitio web es <span className='bold'>totalmente conforme</span> con las <span className='bold'>WCAG versión 2.2</span> en su <span className='bold'>nivel AA</span>.
            </p>
{/* TOTALMENTE CONFORME. */}
            
            <h2 className='heading-text-left margin-bottom-m border-left-padding'>
            Tecnologías Utilizadas 
            </h2>
            <p className="margin-bottom-m p-text">
            El siguiente es el listado de las tecnologías utilizadas en el sitio web:
            </p>
            <ul className="margin-bottom-l p-text">
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>WAI-ARIA</li>
            </ul>

            <h2 className='heading-text-left margin-bottom-m border-left-padding'>
            Navegadores Soportados 
            </h2>
            <p className="margin-bottom-m p-text">
            La siguiente tabla muestra los navegadores cuyo soporte ha sido validado:
            </p>
            {/*
            <ul className="margin-bottom-m p-text">
                <li>En Android: Google Chrome y Microsoft Edge</li>
                <li>En iOS: Google Chrome y Safari</li>
                <li>En macOS: Google Chrome y Safari</li>
                <li>En Windows: Google Chrome y Microsoft Edge</li>
            </ul>
            */}
            
            <table className="margin-bottom-m table-browser">
                <tr>
                    <th>Sistema Operativo</th>
                    <th>Navegador</th>
                </tr>
                <tr lang='en-US'>
                    <td>Android</td>
                    <td>Google Chrome <span lang='es-419'>y</span> Microsoft Edge</td>
                </tr>
                <tr lang='en-US'>
                    <td>iOS</td>
                    <td>Google Chrome <span lang='es-419'>y Safari</span></td>
                </tr>
                <tr lang='en-US'>
                    <td>macOS</td>
                    <td>Google Chrome <span lang='es-419'>y Safari</span></td>
                </tr>
                <tr lang='en-US'>
                    <td>Windows</td>
                    <td>Google Chrome <span lang='es-419'>y</span> Microsoft Edge</td>
                </tr>
            </table>

            <p className="margin-bottom-l p-text">
            Sin perjuicio de lo anterior, pueden existir otros navegadores que también estén siendo soportados por el sitio web.
            </p>


            <h2 className='heading-text-left margin-bottom-m border-left-padding'>
            Preparación de la presente Declaración
            </h2>
            <p className="margin-bottom-m p-text">
            La presente declaración fue preparada el <span className='bold'>18 de julio de 2024</span>
            </p>
            <p className="margin-bottom-m p-text">
            El método empleado para preparar la declaración ha sido una autoevaluación llevada a cabo por nuestro equipo que consideró la ejecución de pruebas manuales y utilización de herramientas automatizadas.
            </p>
            <p className="margin-bottom-l p-text">
            La última revisión de la declaración fue realizada el <span className='bold'>01 de Octubre de 2024</span>
            </p>
            <h2 className='heading-text-left margin-bottom-m border-left-padding'>
            Observaciones y Datos de Contacto
            </h2>
            <p className="margin-bottom-m p-text">
            Cualquier usuario del sitio web puede contactarse para:
            </p>
            <ul className="margin-bottom-m p-text">
                <li>Informar sobre cualquier <span className='bold'>incumplimiento</span> por parte de este sitio web</li>
                <li>Comunicar otras <span className='bold'>dificultades de acceso</span> al contenido</li>
                <li>Realizar cualquier <span className='bold'>consulta o sugerencia de mejora</span> relacionada a la accesibilidad del sitio web</li>
            </ul>
            <p className="margin-bottom-m p-text">
            a través del <Link to={{}} onClick={scrollToFooter} className='a-underline bold'>formulario de contacto</Link> o mediante el correo electrónico <Link to={"mailto:contacto@somosdias.com"}className='a-underline bold'>contacto@somosdias.com</Link>; el cual será respondido a la brevedad.
            </p>
        </div>
        </div>
        </div>
        </>
    )
}

export default AccessibilityStatement