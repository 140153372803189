import React, {useImperativeHandle, useRef, forwardRef, useState, useEffect} from 'react'
import { Button } from './ButtonIcon'
import './Footer.css'
import { Link } from 'react-router-dom'
import { useThemeContext } from '../context/ThemeContext';
import axios from 'axios';
import Alert from './Alert'

const Footer = forwardRef((props, ref) => {
    const myRef = useRef();
    useImperativeHandle(ref,() => ({
        scrollIntoView: () => {
            myRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }));

    const {contextTheme} = useThemeContext();

    const scrollToTop = () => {
        window.scrollTo({top: 0});
    };

   /* form, logic using one var (inputs) */
    const [inputs, setInputs] = useState({});
    //const [apiResult, setApiResult] = useState(null);
    //const [apiError, setApiError] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    const alert = document.getElementById('submitAlert');
    const submitButton = document.getElementById('submitButton');

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        submitButton.disabled = true;
        event.preventDefault();
        //axios.post('http://localhost:80/api/quotes/store', inputs)
        //axios.post('https://dias.thecgstore.com/api/quotes/store', inputs)
        axios.post('https://somosdias.com/api/quotes/store', inputs)
        .then(function(response) {
            console.log("handleSubmit, response: ", response);
            //setApiResult(response);
            response.data.status === 1 ? alert.style.backgroundColor = "darkgreen": alert.style.backgroundColor = "darkred";
            response.data.message ? setAlertMessage(response.data.message) : setAlertMessage("No hemos podido procesar tu consulta. Por favor, intenta más tarde.");
            //setAlertMessage(response.data.message);
        })
        //.catch(error => console.log("Ha ocurrido un error. code: ",error))
        .catch(function(error) {
            console.log("handleSubmit, error: ", error);
            //setApiError(error);
            alert.style.backgroundColor = "darkred"
            //setAlertMessage(error.message);
            //setAlertMessage(apiError);
            setAlertMessage("No hemos podido procesar tu consulta. Por favor, intenta más tarde.");
        })
        //.finally(() => console.log('handleSubmit completed'));
        .finally(() => {
            console.log('handleSubmit completed');
            alert.classList.remove('alert-false');
            alert.classList.add('alert-true');
            submitButton.disabled = false;
            resetInputs();
        })
        console.log(inputs);
    }

    const resetInputs = () => {
        setInputs({
            username: "",
            email: "",
            service: "",
            message: ""
          });
    }

    return (
        <div id='footer' ref={myRef} className='footer-container'>
            <section className='footer-subscription'>
                <h1 className='footer-subscription-heading'>
                    ¿Quieres que te contactemos?
                </h1>
                <p className='footer-subscription-text semi-bold'>
                    Por favor, envíanos los siguientes datos para contactarte:
                </p>
<form
onSubmit={handleSubmit} className='my-form'>
                <div className="my-input-field">
                    <input id='username' name='username' className={`border-${contextTheme}`} type='text' maxLength={50} value={inputs.username} onChange={handleChange} autoComplete='name' required />
                    <label htmlFor='username'>Nombre</label>
                </div>
                <div className="my-input-field">
                    <input id='email' name='email' className={`border-${contextTheme}`} type='email' placeholder=' ' maxLength={50} value={inputs.email} onChange={handleChange} autoComplete='email' required />
                    <label htmlFor='email'>Correo</label>
                </div>
                <div className="my-input-field">
                    <select id="service" name="service" className={`border-${contextTheme}`} value={inputs.service} onChange={handleChange} required>
                        <option id={`defaultoption-${contextTheme}`} value="" disabled selected>Selecciona motivo:</option>
                        <option value="audit">Cotizar Auditoría</option>
                        <option value="consu">Cotizar Consultoría</option>
                        <option value="remed">Cotizar Remediación</option>
                        <option value="prob">Informar Problema</option>
                        <option value="otro">Otro</option>
                    </select>
                </div>
                <div className="my-input-field">
                    <textarea id='message' name="message" className={`border-${contextTheme}`} maxLength={500} value={inputs.message} onChange={handleChange} required />
                    <label htmlFor='message'>Mensaje</label>
                </div>
                <Button
                id='submitButton'
                type='submit'
                buttonStyle={`btn--${contextTheme}`}
                d='M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12.2929 14.2929C11.9024 14.6834 11.9024 15.3166 12.2929 15.7071C12.6834 16.0976 13.3166 16.0976 13.7071 15.7071L16.6201 12.7941C16.6351 12.7791 16.6497 12.7637 16.6637 12.748C16.87 12.5648 17 12.2976 17 12C17 11.7024 16.87 11.4352 16.6637 11.252C16.6497 11.2363 16.6351 11.2209 16.6201 11.2059L13.7071 8.29289C13.3166 7.90237 12.6834 7.90237 12.2929 8.29289C11.9024 8.68342 11.9024 9.31658 12.2929 9.70711L13.5858 11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H13.5858L12.2929 14.2929Z'
                >Enviar
                </Button>
</form>
{/*}
<div id="submitAlert" className="alert" style={{display:'none'}}>
  <span className="closebtn" onClick={hideAlert}>&times;</span> 
  {result.message}
</div>
*/}
{/*}
<div>
    {apiError ? <p>Ocurrió un problema: {apiError.message}</p> : null}
    {apiResult ? <p>{apiResult.data.message}</p> : null}
</div>
*/}

{/*
{apiError ?
<div id="submitAlert" className="alert">
    <span className="closebtn" onClick={hideAlert}>&times;</span>
    <p>Ocurrió un problema: {apiError.message}</p>
</div> : null}
{apiResult ?
<div id="submitAlert" className="alert">
    <span className="closebtn" onClick={hideAlert}>&times;</span>
    <p>{apiResult.data}</p>
</div> : null}
*/}

{/*
{apiError ?
<Alert message={apiError.message}></Alert>
: null}
*/}
<Alert message={alertMessage}></Alert>

            </section>
            <div className={`footer-links footer-links-${contextTheme}`}>
                <div className='footer-link-wrapper'>

                    <div className='footer-link-items'>
                        <h3>Sobre DIAS</h3>
                        <Link to='/accessibilityStatement'>Declaración de Accesibilidad</Link>
                        
                        <Link
                        className='social-icon-link instagram'
                        to='https://instagram.com/somos.dias'
                        target='_blank'
                        aria-label='Instagram'>
                            <i className='fab fa-instagram' /> Instagram
                        </Link>
                        {/*
                        <Link
                        className='social-icon-link linkedin'
                        to='https://www.linkedin.com/company/somosdias'
                        target='_blank'
                        aria-label='Linkedin'>
                            <i className='fab fa-linkedin' /> LinkedIn
                        </Link>
                        */}
                    </div>

{/*
                    <div className='footer-link-items'>
                        <h2>Redes sociales</h2>
                        <Link
                        className='social-icon-link facebook'
                        to='/'
                        target='_blank'
                        aria-label='Facebook'>
                            <i className='fab fa-facebook' /> Facebook
                        </Link>
                        <Link
                        className='social-icon-link instagram'
                        to='/'
                        target='_blank'
                        aria-label='Instagram'>
                            <i className='fab fa-instagram' /> Instagram
                        </Link>
                        <Link
                        className='social-icon-link linkedin'
                        to='/'
                        target='_blank'
                        aria-label='Linkedin'>
                            <i className='fab fa-linkedin' /> LinkedIn
                        </Link>
                        <Link
                        className='social-icon-link twitter'
                        to='/'
                        target='_blank'
                        aria-label='X (Twitter)'>
                            <i className='fab fa-x-twitter' /> X (Twitter)
                        </Link>
                    </div>
*/}
                    
                    <div className='footer-link-items'>
                        <h3>Enlaces de interés</h3>
                        {/*<Link to='https://disfam.org/chile/'>Disfam Chile</Link>*/}
                        <Link to='https://www.w3.org/WAI/standards-guidelines/es'>Estándares W3C WAI</Link>
                        {/*<Link to='https://www.fundacionamanecer.cl/'>Fundación Amanecer</Link>*/}
                        <Link to='https://www.accessibilityassociation.org/s/'>IAAP</Link>
                        <Link to='https://www.senadis.gob.cl/'>SENADIS</Link>
                    </div>
                </div>
            </div>
            {/*}
            <div className={`footer-links footer-links-${contextTheme}`}>
            <Button buttonStyle={`btn--${contextTheme}`}
            d='M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16L13 10.4142L14.2929 11.7071C14.6834 12.0976 15.3166 12.0976 15.7071 11.7071C16.0976 11.3166 16.0976 10.6834 15.7071 10.2929L12.7941 7.37993C12.7791 7.36486 12.7637 7.35031 12.748 7.33627C12.5648 7.12998 12.2976 7 12 7C11.7024 7 11.4352 7.12998 11.252 7.33627C11.2363 7.3503 11.2209 7.36486 11.2059 7.37993L8.29289 10.2929C7.90237 10.6834 7.90237 11.3166 8.29289 11.7071C8.68342 12.0976 9.31658 12.0976 9.70711 11.7071L11 10.4142L11 16Z'
            id="btn_to_top"
            onClick={scrollToTop}>Volver arriba</Button>
            </div>
            */}
        </div>    
    )

})

export default Footer