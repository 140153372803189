import React from 'react';
import '../../App.css';
import CardProfile from '../CardProfile';
import img_cg from '../../assets/images/profile_cg_800.jpg'
import img_dg from '../../assets/images/profile_dg_800-min.jpg'

/*
import {ReactComponent as IcoAboutVision} from '../../assets/images/ico_about_vision.svg'
import {ReactComponent as IcoAboutMission} from '../../assets/images/ico_about_mission.svg'
import {ReactComponent as IcoAboutPrinciples} from '../../assets/images/ico_about_principles.svg'
*/

import imageAboutVision from '../../assets/images/image-about-vision-min.jpg'
import imageAboutMission from '../../assets/images/image-about-mission-min.jpg'
import imageAboutPrinciples from '../../assets/images/image-about-principles-min.jpg'

import {ReactComponent as IcoLetterA} from '../../assets/images/letter-a.svg'
import {ReactComponent as IcoLetterD} from '../../assets/images/letter-d.svg'
import {ReactComponent as IcoLetterI} from '../../assets/images/letter-i.svg'
import {ReactComponent as IcoLetterS} from '../../assets/images/letter-s.svg'


import {Icon} from '../Icon';

import { ListCustomBullet } from '../ListCustomBullet';

import Quote from '../Quote'

const profiles = 
    [
        {
            src: img_dg,
            alt: 'primer plano de Daniela Gonzales',
            name: 'Daniela Gonzales',
            title: 'Fonoaudióloga',
            subtitle: 'Cofundadora',
            /*description: 'Profesional con experiencia en trastornos del lenguaje y neurodiversidad; con formación en método TEACCH, modelo HRI, y terapia ABA.',*/
            description: 'Profesional con experiencia en trastornos del lenguaje y neurodiversidad; con formación en método TEACCH, terapia ABA, y herramientas para educación inclusiva y gestión educativa para la inclusión.',
            url: 'https://cl.linkedin.com/in/daniela-gonzales-949159b0',
            css: 'cp-card'
        },
        {
            src: img_cg,
            alt: 'primer plano de Cristian Gonzales',
            name: 'Cristian Gonzales',
            title: 'Ingeniero en Informática',
            subtitle: 'Cofundador',
            description: 'Profesional certificado por la Asociación Internacional de Profesionales de Accesibilidad (IAAP); con experiencia en desarrollo de productos digitales y gestión de servicios informáticos.',
            url: 'https://cl.linkedin.com/in/cristian-gonzales',
            css: 'cp-card'
        }

    ]


    const paragraphD = <div style={{alignItems: 'center', display: 'flex',flexDirection: 'column'}}><p className='p-large-left'><span className='bold'>Diversidad</span></p><p className='p-large-left' style={{textAlign: 'center', marginTop: '5px'}}>Reconocer la diversidad</p></div>
    const paragraphI = <div style={{alignItems: 'center', display: 'flex',flexDirection: 'column'}}><p className='p-large-left'><span className='bold'>Inclusión</span></p><p className='p-large-left' style={{textAlign: 'center', marginTop: '5px'}}>Trabajar por la inclusión</p></div>
    const paragraphA = <div style={{alignItems: 'center', display: 'flex',flexDirection: 'column'}}><p className='p-large-left'><span className='bold'>Accesibilidad</span></p><p className='p-large-left' style={{textAlign: 'center', marginTop: '5px'}}>Promover la accesibilidad</p></div>
    const paragraphS = <div style={{alignItems: 'center', display: 'flex',flexDirection: 'column'}}><p className='p-large-left'><span className='bold'>Sinergia</span></p><p className='p-large-left' style={{textAlign: 'center', marginTop: '5px'}}>Generar sinergia</p></div>

    const principles = 
    [
        {
            id: 0,
            name: paragraphD,
            description: '',
            icon: IcoLetterD,
        },
        {
            id: 1,
            name: paragraphI,
            description: '',
            icon: IcoLetterI,
        },
        {
            id: 2,
            name: paragraphA,
            description: '',
            icon: IcoLetterA,
        },
        {
            id: 3,
            name: paragraphS,
            description: '',
            icon: IcoLetterS,
        }

    ]

function AboutUs() {
    window.scrollTo({top: 0});

    const principlesLi = 
        <ListCustomBullet
        listType='ol'
        listTitle=''
        listSize={'list--simple'}
        className={'list-letters'}
        children={principles.map(({id, name, icon}) =><li className='list--size--letters' key={id}><Icon cssClassIcon='icon-aboutus' Image={icon} />{name}</li>)}>
        </ListCustomBullet>

    const principlesP = <div><p><span className='bold'>DIAS</span> nace a partir de una idea compartida por nuestros fundadores: <span className='bold'>"ayudar a construir una sociedad más inclusiva"</span>, y desarrolla su labor siguiendo <span className='bold'>4 principios</span>:</p>{principlesLi}</div>;

    /*
    Desde su fundación, DIAS ha desarrollado su quehacer siguiendo los <span className='bold'>4 principios</span> establecidos por nuestros fundadores:
    DIAS nace a partir de una idea compartida por nuestros fundadores: "ayudar a construir una sociedad más inclusiva", y se basa en 4 principios fundamentales: reconocer la Diversidad, trabajar por la Inclusión, promover la Accesibilidad, y generar Sinergia.
    */

    return (
        <>
        <div className='container-parent margin-bottom-xl'>
            <Quote className='blockquote--secondary'
                    quote='Somos DIAS, una empresa especializada en servicios de accesibilidad digital que, a través del trabajo colaborativo, busca fomentar la equidad digital y así ayudar a construir una sociedad más inclusiva.'
                    author='Quienes somos'
                />
            <div className='container-child'>
                {/*}
                <h1 className='aboutUs'>¿Quiénes somos?</h1>
                */}
                <div className='div-center'>
                    {/*}
                    <p className='semi-bold'>
                    Somos DIAS, una empresa especializada en servicios de accesibilidad digital que, a través del trabajo colaborativo, busca fomentar la equidad digital con el objeto de ayudar a construir una sociedad más inclusiva.
                    </p>
                    */}
                    <h1 className='heading-text-aboutus w-dynamic margin-top-xl'>
                        Nuestra Visión
                    </h1>
                    {/*
                    <Icon cssClassIcon='icon-aboutus' Image={IcoAboutVision} cssClassName='margin-top-s' />
                    */}
                    <img src={imageAboutVision} alt='primer plano del ojo de una persona mirando de frente' className='w-dynamic' />
                    <p className='p-large-left w-dynamic margin-top-s'>
                        Ser el <span className='bold'>socio estratégico</span> de aquellas organizaciones interesadas en ofrecer <span className='bold'>productos digitales accesibles</span>. Queremos una <span className='bold'>sociedad más inclusiva</span> por lo que trabajamos para <span className='bold'>eliminar las barreras digitales</span>.
                        {/* Buscamos ser el <span className='bold'>socio estratégico</span> de aquellas organizaciones interesadas en ofrecer <span className='bold'>productos digitales accesibles</span>, para que puedan ser utilizados por el <span className='bold'>mayor número de personas</span>. */}
                        {/* Buscamos ser el <span className='bold'>aliado estratégico</span> de aquellas organizaciones interesadas en ofrecer productos digitales que puedan ser utilizados por el mayor número de personas. Queremos una <span className='bold'>sociedad más inclusiva</span> por lo que trabajamos para <span className='bold'>eliminar las barreras digitales</span>. */}
                    </p>
                    
                    <h1 className='heading-text-aboutus w-dynamic margin-top-xl'>
                        Nuestra Misión
                    </h1>
                    {/*
                    <Icon cssClassIcon='icon-aboutus' Image={IcoAboutMission} cssClassName='margin-top-m margin-bottom-s' />
                    */}
                    <img src={imageAboutMission} alt='nave espacial despegando' className='w-dynamic' />
                    <p className='p-large-left w-dynamic margin-top-s'>
                        Entregar <span className='bold'>servicios profesionales de accesibilidad digital</span> que ayuden a las organizaciones a generar productos digitales que brinden una <span className='bold'>mejor experiencia de usuario</span> y puedan ser <span className='bold'>usados por el mayor número de personas</span>.
                        {/* Nuestro objetivo es entregar <span className='bold'>servicios profesionales de accesibilidad digital</span> que ayuden a las organizaciones a generar productos digitales que brinden una <span className='bold'>mejor experiencia de usuario</span>. */}
                        {/* Nuestro objetivo es entregar <span className='bold'>servicios profesionales de accesibilidad digital</span> que ayuden a las organizaciones a generar <span className='bold'>productos digitales accesibles</span> que brinden una <span className='bold'>mejor experiencia de usuario</span> para todas las personas. */}
                    </p>

                    <h1 className='heading-text-aboutus w-dynamic margin-top-xl'>
                        Nuestros Principios
                    </h1>
                    {/*
                    <Icon cssClassIcon='icon-aboutus' Image={IcoAboutPrinciples} cssClassName='margin-top-m margin-bottom-s' />
                    */}
                    <img src={imageAboutPrinciples} alt='un grupo de personas que forman un círculo apoyan sus manos entre sí' className='w-dynamic' />
                    <div className='p-large-left w-dynamic margin-top-s'>
                        {principlesP}
                    </div>

                    <h1 className='heading-text-aboutus w-dynamic'>
                        Nuestros Fundadores
                    </h1>
                </div>

                <div className="capp-cards w-60">
                {profiles.map((card, index) => {
                    return (
                        <CardProfile key={index}
                        src={card.src}
                        name={card.name}
                        alt={card.alt}
                        title={card.title}
                        subtitle={card.subtitle}
                        description={card.description}
                        url={card.url}
                        css={card.css}
                        />)
                    }
                )}
                </div>
            </div>
        </div>
        </>
    )
}

export default AboutUs