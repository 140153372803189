import React from 'react'
import '../../App.css'
import Quote from '../Quote'
import {Link} from 'react-router-dom';

import img_pedestrian from '../../assets/images/image-pedestrian-min.jpg'

import {ReactComponent as IcoBarriersComplex} from '../../assets/images/ico_img_barriers_complex.svg'
import {ReactComponent as IcoBarriersKeyboard} from '../../assets/images/ico_img_barriers_keyboard_navigation.svg'
import {ReactComponent as IcoBarriersContrast} from '../../assets/images/ico_img_barriers_low_contrast.svg'

import {ReactComponent as IcoBarriersComplexRemoved} from '../../assets/images/ico_img_barriers_removed_complex.svg'
import {ReactComponent as IcoBarriersKeyboardRemoved} from '../../assets/images/ico_img_barriers_removed_keyboard_navigation.svg'
import {ReactComponent as IcoBarriersContrastRemoved} from '../../assets/images/ico_img_barriers_removed_low_contrast.svg'

import {ReactComponent as IcoBenefitsAudience} from '../../assets/images/ico_img_benefits_audience_4.svg'
import {ReactComponent as IcoBenefitsBrand} from '../../assets/images/ico_img_benefits_brand.svg'
import {ReactComponent as IcoBenefitsInnovation} from '../../assets/images/ico_img_benefits_innovation.svg'
import {ReactComponent as IcoBenefitsLegalRisk} from '../../assets/images/ico_img_benefits_legal_risk.svg'

import {ReactComponent as IcoOBenefitsEnvironment} from '../../assets/images/ico_o_benefits_environment_limitation_img.svg'
import {ReactComponent as IcoOBenefitsInjury} from '../../assets/images/ico_o_benefits_injury_img.svg'
import {ReactComponent as IcoOBenefitsOldPerson} from '../../assets/images/ico_o_benefits_old_person_img.svg'
import {ReactComponent as IcoOBenefitsSmallScreen} from '../../assets/images/ico_o_benefits_small_screen_img.svg'

import {ReactComponent as IcoBasedOnUD} from '../../assets/images/ico_basedon_ud.svg'
import {ReactComponent as IcoBasedOnWCAG} from '../../assets/images/ico_basedon_wcag.svg'

import CardQuote from '../CardQuote'
import { ListIconTitleDesc } from '../ListIconTitleDesc'

const authorText = <span>Tim Berners-Lee, Director fundador del W3C y creador de la <span lang='en-US'>World Wide Web</span></span>

const paragraphChilePercentage = <p className=''>En Chile, el <span className='bold'>17%</span> de la población tiene discapacidad, es decir, más de <span className='bold'>3 millones de personas</span>.</p>;


const barriers = 
    [
        {
            id: 0,
            name: 'Navegación por teclado no soportada',
            /*description: 'Los sitios web que no admiten la navegación por teclado pueden ser inaccesibles para usuarios con discapacidades motoras que navegan usando el teclado.',*/
            Image: IcoBarriersKeyboard,
            cssClassIcon: 'li-icon'
        },
        {
            id: 1,
            name: 'Colores con bajo contraste',
            /*description: 'El contraste insuficiente entre el color del texto y del fondo puede hacer que el contenido sea difícil de leer para usuarios con discapacidades visuales.',*/
            Image: IcoBarriersContrast,
            cssClassIcon: 'li-icon'
        },
        {
            id: 2,
            name: 'Estructura o contenido complejo',
            /*description: 'Los sitios web que tienen estructuras o contenidos complejos pueden ser confusos, especialmente para usuarios con discapacidades cognitivas.',*/
            Image: IcoBarriersComplex,
            cssClassIcon: 'li-icon'
        }

    ]

const barriersRemoved = 
    [
        {
            id: 0,
            name: 'Soporte para navegación por teclado',
            description: '',
            Image: IcoBarriersKeyboardRemoved,
            cssClassIcon: 'li-icon'
        },
        {
            id: 1,
            name: 'Colores con buen contraste',
            description: '',
            Image: IcoBarriersContrastRemoved,
            cssClassIcon: 'li-icon'
        },
        {
            id: 2,
            name: 'Estructura y contenido simple',
            description: '',
            Image: IcoBarriersComplexRemoved,
            cssClassIcon: 'li-icon'
        }

    ]

const otherBenefits = 
    [
        {
            id: 0,
            name: 'Personas que usan dispositivos con pantallas pequeñas',
            description: '',
            Image: IcoOBenefitsSmallScreen,
            cssClassIcon: 'li-icon'
        },
        {
            id: 1,
            name: 'Personas mayores cuyas habilidades cambian con la edad',
            description: '',
            Image: IcoOBenefitsOldPerson,
            cssClassIcon: 'li-icon'
        },
        {
            id: 2,
            name: 'Personas con discapacidades temporales (ej: un brazo lesionado)',
            description: '',
            Image: IcoOBenefitsInjury,
            cssClassIcon: 'li-icon'
        },
        {
            id: 3,
            name: 'Personas con limitaciones de entorno (ej: estar bajo la luz del sol)',
            description: '',
            Image: IcoOBenefitsEnvironment,
            /*cssClassIcon: 'li-icon stroke'*/
            cssClassIcon: 'li-icon'
        }

    ]

const benefits = 
    {
        title: 'Beneficios',
        source: 'Fuente: Iniciativa para la Accesibilidad Web (WAI)',
        benefitsList:
        [
            {
                id: 0,
                name: 'Impulsar la innovación',
                description: '',
                Image: IcoBenefitsInnovation,
                cssClassIcon: 'li-icon'
            },
            {
                id: 1,
                name: 'Mejorar la reputación',
                description: '',
                Image: IcoBenefitsBrand,
                cssClassIcon: 'li-icon'
            },
            {
                id: 2,
                name: 'Ampliar el mercado',
                description: '',
                Image: IcoBenefitsAudience,
                cssClassIcon: 'li-icon'
            },
            {
                id: 3,
                name: 'Minimizar el riesgo legal',
                description: '',
                Image: IcoBenefitsLegalRisk,
                cssClassIcon: 'li-icon'
            }

        ]
    }

const paragraphUD = <p className='li-description'>El <span className='bold'>Diseño Universal</span> (UD, en inglés) vela por el diseño de <span className='bold'>productos y servicios</span> para que sean <span className='bold'>utilizables por todas las personas</span>, en la mayor medida posible, <span className='bold'>sin necesidad de adaptación</span> o diseño especializado.</p>;
const paragraphWCAG = <p className='li-description'>Las <span className='bold'>Pautas de Accesibilidad al Contenido Web</span> (WCAG, en inglés), desarrolladas por la Iniciativa para la Accesibilidad Web (WAI, en inglés), definen los <span className='bold'>requisitos</span> que un sitio web debe cumplir para <span className='bold'>ser accesible</span>.</p>;

const basedOn = 
    [
        {
            id: 0,
            name: 'Diseño Universal',
            description: paragraphUD,
            Image: IcoBasedOnUD,
            cssClassIcon: 'li-icon'
        },
        {
            id: 1,
            name: 'Pautas de Accesibilidad al Contenido Web',
            description: paragraphWCAG,
            Image: IcoBasedOnWCAG,
            cssClassIcon: 'li-icon'
        }

    ]


const Home = ({refToFooter}) => {
    window.scrollTo({top: 0});

    const scrollToFooter = () => {
        if (refToFooter.current) {
          refToFooter.current.scrollIntoView();
        }
      };
    
    const barriersList = barriers.map(({ id, name, description, Image, cssClassIcon }) =>
        <li key={id}>
            <div className='li-container-dynamic'>
                <div className='li-container-dynamic-div'>
                    <Image className={cssClassIcon} src={Image} />
                    <p className='li-title bold'>{name}</p>
                </div>
                <p className='li-description'>{description}</p>
            </div>
        </li>);
    
    const barriersRemovedList = barriersRemoved.map(({ id, name, description, Image, cssClassIcon }) =>
        <li key={id}>
            <div className='li-container'>
                <Image className={cssClassIcon} src={Image} />
                <p className='li-title bold'>{name}</p>
            </div>
        </li>);

    const otherBenefitsList = otherBenefits.map(({ id, name, description, Image, cssClassIcon }) =>
        <li key={id}>
            <div className='li-container'>
                <Image className={cssClassIcon} src={Image} />
                <p className='li-description'>{name}</p>
            </div>
        </li>);

    const benefitsList = benefits.benefitsList.map(({ id, name, description, Image, cssClassIcon }) =>
        <li key={id}>
            <div className='li-container'>
                {/*<div className='circle'>*/}
                    <Image className={cssClassIcon} src={Image} />
                {/*</div>*/}
                <p className='li-title'>{name}</p>
            </div>
        </li>);

    const basedOnList = basedOn.map(({ id, name, description, Image, cssClassIcon }) =>
        <li className='border' key={id}>
            <div className='li-container-column'>
                <div className='circle'>
                    <Image className={cssClassIcon} src={Image} />
                </div>
                <p className='li-title'>{name}</p>
                {description}
            </div>
        </li>);

    return (
        <>
        <div className='container-parent margin-bottom-xl'>
            <Quote
                quote='El poder de la Web está en su universalidad. El acceso de todos, independientemente de la discapacidad, es un aspecto esencial'
                author={authorText}
                hasIcon='true'
            />

            <CardQuote key='c-qt-1'
                src={img_pedestrian}
                alt='una multitud de personas caminando por la calle'
                description={paragraphChilePercentage}
                subdescription='Fuente: III Estudio Nacional de la Discapacidad 2022'
                css={'card-qt'}
            />


            <h1 className='heading-text'>
                Barreras de Accesibilidad
            </h1>

            <p className='container-child p-text'>
                Cuando los sitios web están bien diseñados, todos pueden utilizarlos. Sin embargo, en la actualidad muchos sitios web tienen <span className='bold'>barreras de accesibilidad</span> que dificultan o imposibilitan su uso por parte de algunas personas, como por ejemplo:
            </p>

            <ListIconTitleDesc
                className=''
                listType='listITD'
                listTitle=''
                listSize={'list--size-s'}
                children={barriersList}>
            </ListIconTitleDesc>

            <h1 className='heading-text'>
                ¿Se pueden eliminar las barreras?
            </h1>
            
            <p className='container-child p-text'>
                Sí, la <span className='bold'>accesibilidad digital</span> permite eliminar o mitigar las barreras a las que se enfrentan las personas en los entornos digitales. Los sitios web que incorporan accesibilidad digital se transforman en <span className='bold'>sitios web accesibles</span> que poseen, entre otras, las siguientes características: 
            </p>
            
            <ListIconTitleDesc
                className=''
                listType='listITD'
                listTitle=''
                listSize={'list--size-s'}
                children={barriersRemovedList}>
            </ListIconTitleDesc>
            
            <h1 className='heading-text'>
                ¿Por qué hacer sitios web accesibles?
            </h1>
            
            <p className='container-child p-text margin-bottom-s'>
                Porque los sitios web accesibles mejoran la experiencia de <span className='bold'>todos los usuarios</span>, ya que no solo permiten que las personas con discapacidad puedan utilizarlos, sino que también facilitan su uso por parte de <span className='bold'>personas sin discapacidad</span>, como por ejemplo:
                {/*Hacer sitios web accesibles permite que las <span className='bold'>personas con discapacidad</span> (física, sensorial, cognitiva, entre otras) puedan <span className='bold'>acceder a ellos y utilizarlos</span>. Adicionalmente, los sitios web accesibles <span className='bold'>benefician a personas sin discapacidad</span>, como por ejemplo:*/}
            </p>
            
            <ListIconTitleDesc
                className=''
                listType='listITD'
                listTitle=''
                listSource=''
                listSize={'list--size-s'}
                children={otherBenefitsList}>
            </ListIconTitleDesc>
            
            <p className='margin-top-m container-child p-text margin-bottom-s'>
                Por otra parte, los sitios web accesibles reportan grandes <span className='bold'>beneficios para las empresas</span> que los ofrecen, ya que permiten, entre otras cosas:
            </p>
            
            <ListIconTitleDesc
                className=''
                listType='listITD'
                listTitle=''
                listSource='Fuente: Iniciativa para la Accesibilidad Web (WAI)'
                listSize={'list--size-s'}
                children={benefitsList}>
            </ListIconTitleDesc>

            <h1 className='heading-text'>
                ¿Quieres que tu sitio web sea accesible?
            </h1>
            
            <p className='container-child p-text'>
                Si quieres hacer que tu sitio web sea accesible, te podemos ayudar. Nuestros servicios, basados en los principios del Diseño Universal y en las Pautas de Accesibilidad al Contenido Web, unido a profesionales certificados, nos posiciona como el <span className='bold'>socio estratégico</span> de las organizaciones interesadas en ofrecer <span className='bold'>productos digitales accesibles</span>.
            </p>

            <p className='container-child p-text margin-top-paragraph'>
                Si quieres que tus productos digitales puedan ser usados por el mayor número personas, <Link to={{}} onClick={scrollToFooter} className='a-underline'>contáctanos</Link>, <span className='bold'>somos especialistas</span> en accesibilidad digital.
            </p>

        </div>

        </>
    )
}

export default Home