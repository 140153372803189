import React, {useState} from 'react';
import './Alert.css';

function Alert(props) {
    const [isShow, setIsShow] = useState(false);

    const alert = document.getElementById('submitAlert');

    const handleClose = (e) => {
        e.preventDefault();
        setIsShow(false);
        alert.classList.remove('alert-true');
        alert.classList.add('alert-false');
    }

        /*<div id="submitAlert" className={`alert-${isShow} alert primary`}>*/
    return (
        <div id="submitAlert" className={`alert-false alert primary`}>
            {props.message}
            <span className='closebtn' onClick={handleClose}> &times;</span>
        </div>
    )
}

export default Alert