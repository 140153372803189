import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import './Navbar.css';
//import {Button} from './Button';
import {Button} from './ButtonIcon';
import {ReactComponent as Logo} from '../assets/images/icono_nombre_dias.svg';
import ReactSwitch from 'react-switch';
import { useThemeContext } from '../context/ThemeContext';

const Navbar = ({refToFooter}) => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  
  //usar tema de contexto
  const {contextTheme, setContextTheme} = useThemeContext()

  // checked: var asociada al switch, useState(false) = light mode; useState(true) = dark mode
  //const [checked, setChecked] = useState(false);
  //const [checked, setChecked] = useState(localStorage.getItem('isDarkMode') === 'true'?true:false);
  const [checked, setChecked] = useState(contextTheme === 'dark__mode'?true:false);
  
  const body = document.body;

  //console.log("prefers usuario dark?: ",window.matchMedia('(prefers-color-scheme: dark)').matches)
  //console.log("localStorage dark? preset: ",localStorage.getItem('isDarkMode'))
  
  /*
  if(localStorage.getItem('isDarkMode')) {
    console.log('existe')
  } else {
    console.log('NO existe, la crea')
    localStorage.setItem('isDarkMode', checked);
  }
  */
  if(localStorage.getItem('isDarkMode') === null) {
    console.log('No existe la llave, la crea')
    localStorage.setItem('isDarkMode', checked);
  }
  
  //console.log("localStorage dark? postset: ",localStorage.getItem('isDarkMode'))

  if (localStorage.getItem('isDarkMode') === 'true') {
    //console.log("entro a checked dark__mode")
    body.classList.add('dark__mode');
    body.classList.remove('light__mode');
  } else if (localStorage.getItem('isDarkMode') === 'false') {
    //console.log("entro a checked light__mode")
    body.classList.add('light__mode');
    body.classList.remove('dark__mode');
  }

  //fx para cambiar ícono de menú al hacer click en él
  // const handleClick = () => setClick(!click);
  const handleClick = () => {
    setClick(!click);
    //console.log('clic es: ', click);
    if(click) {
      body.classList.remove('nooverflow');
    } else {
      body.classList.add('nooverflow');
    }
  }
  
  //fx para manejar el switch
  const handleSwitch = (nextChecked) => {
    //console.log("entro a handleSwitch")
    setContextTheme((state) => (state === 'light__mode'?'dark__mode':'light__mode'));
    localStorage.setItem('isDarkMode', nextChecked);
    setChecked(nextChecked);
    //console.log("handleSwitch - localStorage dark?: ",localStorage.getItem('isDarkMode'))
  };

  //console.log("localStorage dark?: ",localStorage.getItem('isDarkMode'))

  // hook para agregar/remover clase asociada al tema al body
  useEffect(() => {
    //console.log('useEffect checked : ', checked)
    if (localStorage.getItem('isDarkMode') === 'true') {
      setContextTheme('dark__mode');
      setChecked(true)
      //console.log("useEffect dark__mode")
      body.classList.add('dark__mode');
      body.classList.remove('light__mode');
    } else if (localStorage.getItem('isDarkMode') === 'false') {
      setContextTheme('light__mode');
      setChecked(false)
      //console.log("useEffect light__mode")
      body.classList.add('light__mode');
      body.classList.remove('dark__mode');
    }
  }, [checked]);

  
  //fx para cerrar menú en versión mobile
  const closeMobileMenu = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setClick(false);
    body.classList.remove('nooverflow');
  };

  //fx para mostrar el botón en versión desktop (ocultar en mobile)
  /*const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false)
    } else {
      setButton(true)
    }
  };*/
  const showButton = () => {
    setButton(true)
  };

  //fx para scrolldown
  const scrollToFooter = () => {
    if (refToFooter.current) {
      refToFooter.current.scrollIntoView();
      setClick(false);
      body.classList.remove('nooverflow');
    }
  };

  useEffect(() => {
    showButton()
  }, [])

  window.addEventListener('resize',showButton);

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <>
      {/*<nav className='navbar'>*/}
      <nav className={`navbar nav-${contextTheme}`}>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          {/*<Link to='/' className={`navbar-logo ${contextTheme}`} onClick={closeMobileMenu}>*/}
          {/*<img className='img-logo' src={icono} alt='DIAS logo'/> DIAS*/}
            <Logo className={`img-logo img-logo-${contextTheme}`} alt='DIAS logo' />
            {/*<img className={`img-logo-${contextTheme}`} src={icono} alt='DIAS logo'/>*/}
          </Link>
          <ul className={click ? 'nav-menu active nav-menu-'+contextTheme : 'nav-menu nav-menu-'+contextTheme}>
            <li className='nav-item'>
              {/*<Link to='/' className='nav-links' onClick={closeMobileMenu}>*/}
              <Link to='/' className={`nav-links nav-links-${contextTheme} ${location.pathname === "/" && "border-active-"+contextTheme}`} onClick={closeMobileMenu}>
                Inicio
              </Link>
            </li>
            <li className='nav-item'>
              {/*<Link to='/services' className='nav-links' onClick={closeMobileMenu}>*/}
              <Link to='/services' className={`nav-links nav-links-${contextTheme} ${location.pathname === "/services" && "border-active-"+contextTheme}`} onClick={closeMobileMenu}>
                Servicios
              </Link>
            </li>
            <li className='nav-item'>
              {/*<Link to='/aboutUs' className='nav-links' onClick={closeMobileMenu}>*/}
              <Link to='/aboutUs' className={`nav-links nav-links-${contextTheme} ${location.pathname === "/aboutUs" && "border-active-"+contextTheme}`} onClick={closeMobileMenu}>
                ¿Quiénes somos?
              </Link>
            </li>
          </ul>
          {/*}
          {button && <Button buttonStyle={`btn--outline-${contextTheme}`} onClick={scrollToFooter}>Contacto</Button>}
          */}
          {button && <Button buttonStyle={`btn--${contextTheme}`} onClick={scrollToFooter}>Contacto</Button>}

          <ReactSwitch
          checked={checked}
          onChange={handleSwitch}
          offColor="#f5f5f5"
          onColor="#454545"
          offHandleColor="#454545"
          onHandleColor="#f5f5f5"
          handleDiameter={28}
          uncheckedIcon={
            <i className="fa-solid fa-sun"></i>
          }
          checkedIcon={
            <i className="fa-solid fa-moon"></i>
          }
          height={40}
          width={70}
          className="react-switch"
          id="small-radius-switch"
           />
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times menu-icon-i-'+contextTheme : 'fas fa-bars menu-icon-i-'+contextTheme} />
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar